import styled from "styled-components";
import { space } from "styled-system";

export const List = styled.ul`
  list-style-type: ${(p) => (p.listStyle ? p.listStyle : "disc")};
  padding-left: 24px;
  margin: 0;

  ${space};
`;

export const ListItem = styled.li`
  padding-left: 8px;
`;
