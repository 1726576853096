import React from "react";
import styled from "styled-components";
import Seo from "../components/Layout/Seo";
import Site from "../components/Layout/Site";
import { Row, Col, Container } from "../components/UI/Grid";
import { List, ListItem } from "../components/UI/List";
import { Section } from "../components/UI/Section";
import { Heading, Paragraph } from "../components/UI/Typography";
import theme from "../utils/theme";
import DefaultVector from "../components/Shared/DefaultVector";

const PrivacyPolicy = () => {
  return (
    <Site>
      <Seo title="Privacy Policy" />
      <Section py={{ _: 16, lg: 0 }}>
        <DefaultVector>
          <Container>
            <Row justifyContent="center">
              <Col col={{ lg: 9 }}>
                <Heading size="display-4" textAlign="center" mb={10}>
                  Privacy Policy
                </Heading>

                <Paragraph mb={2}>
                  This privacy policy describes the personal information that
                  we, “Fastech Synergy Philippines Inc.” (collectively,
                  “Fastech,” “we,” “us” and “our”) collect about you, why we
                  collect it, how we use it, and when we share it with third
                  parties. Using the digital platforms that are part of Fastech,
                  implies that you accept the terms of this privacy policy.
                </Paragraph>

                <Paragraph mb={10}>
                  Furthermore, this privacy policy only applies and refers to
                  information collected online through our official website and
                  social media pages (collectively referred to as "digital
                  platforms") and not to information collected offline.
                </Paragraph>

                <ParagraphTitle>Information Collection</ParagraphTitle>
                <Paragraph mb={2}>
                  We collect information directly from you. We may require you
                  to provide us with certain personally identifiable
                  information, including but not limited to your name, contact
                  information, and location.
                </Paragraph>

                <Paragraph mb={10}>
                  Fastech reserves the right to collect information from its
                  users when users browse the company's websites and submit
                  questions through the Contact Us page.
                </Paragraph>

                <ParagraphTitle>Log Data</ParagraphTitle>
                <Paragraph mb={2}>
                  Whenever you visit our Site, we collect information that your
                  browser sends to us that is called Log Data. This Log Data may
                  include information such as the following:
                </Paragraph>

                <List mb={2}>
                  <ListItem>Computer’s Internet Protocol (IP) address</ListItem>
                  <ListItem>Browser version</ListItem>
                  <ListItem>The pages of our Site that you visit</ListItem>
                  <ListItem>The time and date of your visit </ListItem>
                  <ListItem>The time spent on those pages</ListItem>
                  <ListItem>Other statistics</ListItem>
                </List>

                <Paragraph mb={10}>
                  The data collected will be used for the website's analytics
                  that is collated to assess the website's performance.
                </Paragraph>

                <ParagraphTitle>Information Use</ParagraphTitle>

                <Paragraph mb={2}>
                  The information that we collect from you may be used/helps us
                  to:
                </Paragraph>

                <List mb={10}>
                  <ListItem>
                    Process your queries better, including the sending of emails
                    for a faster and better transaction.{" "}
                  </ListItem>
                  <ListItem>
                    Contact and update you regarding your queries.
                  </ListItem>
                  <ListItem>
                    Better understand your needs and preferences so we can
                    improve our services.
                  </ListItem>
                  <ListItem>Analyze trends and statistics.</ListItem>
                </List>

                <ParagraphTitle>Information Protection</ParagraphTitle>

                <Paragraph mb={10}>
                  As the receiver of your data, we shall ensure that the duties
                  and responsibilities stated in the Data Privacy Act of 2012
                  and its implementing rules and regulations are strictly
                  observed during the processing of your personal information.
                </Paragraph>

                <ParagraphTitle>Information Storage</ParagraphTitle>

                <Paragraph mb={2}>
                  Fastech guarantees that your personal data will not be stored
                  longer than necessary. If the purpose data collected is
                  achieved, and any concerns regarding it are considered
                  resolved or finished, we will immediately remove it from our
                  servers.
                </Paragraph>

                <Paragraph mb={10}>
                  Your information shall only be kept by Fastech for the
                  fulfillment of its obligations and compliance with legal,
                  regulatory, and business requirements, or other standards set
                  forth by a governing body.
                </Paragraph>

                <ParagraphTitle>Children's Privacy</ParagraphTitle>
                <Paragraph mb={10}>
                  Our Site does not address anyone under the age of 18. We do
                  not knowingly collect personal identifiable information from
                  children under 18. In the case we discover that a child under
                  18 has provided us with personal information, we immediately
                  delete this from our servers. If you are a parent or guardian
                  and you are aware that your child has provided us with
                  personal information, please contact us so that we will be
                  able to do necessary actions.
                </Paragraph>

                <ParagraphTitle>Cookies</ParagraphTitle>
                <Paragraph mb={10}>
                  We also collect information from you passively. We may use
                  tracking tools like browser cookies, flash cookies, pixels,
                  and web beacons.
                </Paragraph>
                <ParagraphTitle>Changes to This Privacy Policy</ParagraphTitle>
                <Paragraph mb={{ _: 0, lg: "90px" }}>
                  We may update our Privacy Policy from time to time. Thus, we
                  advise you to review this page periodically for any changes.
                  We will notify you of any changes by posting the new Privacy
                  Policy on this page. These changes are effective immediately,
                  after they are posted on this page.
                </Paragraph>
              </Col>
            </Row>
          </Container>
        </DefaultVector>
      </Section>
    </Site>
  );
};

const ParagraphTitle = styled.div`
  margin-bottom: 16px;
  text-transform: uppercase;
  color: ${theme.colors.brand};
  font-weight: ${theme.fontWeights.bold};
`;

export default PrivacyPolicy;
